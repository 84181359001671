import api from "@/services/apiService"

class MarketService {

  saveGroup(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'group', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateGroup(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'group/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  deleteGroup(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'group/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  rollbackGroup(_id) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'group/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  permanentDeleteGroup(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'group/' + _id + '/permanent')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getGroups() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'group')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  saveMarket(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateMarket(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'market/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  deleteMarket(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  rollbackMarket(_id) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'market/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  permanentDeleteMarket(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/' + _id + '/permanent')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getMarkets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }
}

export default new MarketService();
