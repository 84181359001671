import AuthService from '@/services/authService'
import MarketService from '@/services/marketService'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  userProfile: {
    fullname: '',
    username: ''
  },
  marketGroups: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateProfile (state, profile) {
    state.userProfile = profile
  },
  updateMarketGroup(state, groups) {
    state.marketGroups = groups
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile().then((profile) => {
      commit('updateProfile', profile)
    })
  },
  reloadMarketGroups({ commit }) {
    MarketService.getGroups().then((response)=>{
      if(response.success) {
        commit('updateMarketGroup', response.data)
      }
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
