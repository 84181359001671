import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/manage-lotto/result',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/manage-lotto',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'result',
              name: 'LottoResults',
              component: () => import('@/views/manage-lotto/Results')
            },
            {
              path: 'market',
              name: 'MarketDashboard',
              component: () => import('@/views/manage-lotto/Markets')
            },
            {
              path: 'group',
              name: 'MarketGroup',
              component: () => import('@/views/manage-lotto/Groups')
            }
          ]
        },
        {
          path: '/setting',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: 'seo',
            name: 'SettingSeo',
            component: () => import('@/views/settings/Seo')
          }]
        },
        {
          path: '/manage-banner',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: ':pos',
            name: 'Banner',
            component: () => import('@/views/manage-banner/Banner')
          }]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  console.log('router.beforeEach')

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router
